import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { Login } from './Login'

import { getAll, getMatchingDomainNames, markAllAsChecked } from '../services/mailServices';
import { logUserAction } from '../services/historyServices';
import { toast } from 'react-toastify';

export const Home = () => {
    // const { path, url } = useRouteMatch();
    const [user] = useAuthState(auth);

    const [emails, setEmails] = useState('');

    const theme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#1976D2',
            },
            secondary: {
                main: '#FF4081',
            },
        }
    });

    const [tableData, setTableData] = useState([]);

    const [matchingDomainNames, setMatchingDomainsNames] = useState('');

    const [loading, setLoading] = useState(false);
    const [collectEmailsDisabled, setCollectEmailsDisabled] = useState(false);
    const [extractEmailsDisabled, setExtractEmailsDisabled] = useState(true);
    const [copyDomainsDisabled, setCopyDomainsDisabled] = useState(true);

    // const [tabValue, setTabValue] = useState(0);

    if (!user) {
        return (
            <Login />
        );
    }

    console.log(user.displayName);

    const handleCollectEmails = async () => {

        setLoading(true);
        setCollectEmailsDisabled(true);
        setExtractEmailsDisabled(false);

        setTimeout(() => {
            getAll()
                .then((res) => {
                    setEmails(res);
                    setTableData(res.map((email, index) => ({
                        id: index + 1,
                        domain: email.domainName,
                        dateInTheEmail: email.dateToCompare,
                        dateReceived: email.formattedDateReceived,
                        match: email.areTheTwoDatesTheSame,
                    }))

                    );
                    const matchingEmails = res.filter((email) => email.areTheTwoDatesTheSame);
                    const matchingDomains = matchingEmails.map((email) => email.domainName);

                })
                .catch((error) => {
                    console.error('Error fetching emails:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 1000)
    }

    const handleExtractDomains = async () => {
        try {
            const matchingDomains = await getMatchingDomainNames();
            setMatchingDomainsNames(matchingDomains.join('\n'));
            setExtractEmailsDisabled(true);
            setCopyDomainsDisabled(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCopyToClipboard = () => {
        const textToCopy = matchingDomainNames;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                logUserAction(user.displayName, matchingDomainNames)
                markAllAsChecked()

                console.log('Copied to clipboard:', textToCopy);
                toast.success(`Copied ${textToCopy.split('\n').length} domain names`)
            })
            .catch((error) => {
                console.error('Unable to copy to clipboard:', error);
            });
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'domain', headerName: 'Domain Name', width: 150 },
        { field: 'dateReceived', headerName: 'Date Received', width: 120 },
        { field: 'dateInTheEmail', headerName: 'Email Date', width: 120 },
        { field: 'match', headerName: 'Match', width: 100 },
    ];


    return (

        <Container component="main" maxWidth="md">
            <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" mb={4}>
                    Domain Name Collector
                </Typography>

                <Grid item xs={12} sm={6}>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ border: 1, padding: 2, textAlign: 'center' }}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCollectEmails}
                                sx={{ mr: 2 }}
                                disabled={collectEmailsDisabled}
                            >                                    Collect Emails
                            </Button>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={loading ? [] : tableData}
                                    columns={columns}
                                    pageSize={5}
                                    checkboxSelection
                                    loading={loading}
                                />

                            </div>
                            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleExtractDomains}
                                    sx={{ mr: 2 }}
                                    disabled={extractEmailsDisabled}
                                >
                                    Extract Domains
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ border: 1, padding: 2, textAlign: 'center' }}>
                            <Typography variant="h6" mb={2}>
                                Extracted Domain Names
                            </Typography>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Extracted Emails"
                                multiline
                                maxRows={10}
                                fullWidth
                                margin="normal"
                                value={matchingDomainNames} // Update value with extracted emails from state
                                helperText="Extracted domain names will appear here..."
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCopyToClipboard}
                                disabled={copyDomainsDisabled}
                                mt={2}>
                                Copy to Clipboard
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
