import { db } from '../firebase';
import { getDocs, setDoc, doc,  collection } from 'firebase/firestore';

const historyCollectionRef = collection(db, 'history');


export const logUserAction = async (user, domainNames) => {
    try {
      const padZero = (num) => (num < 10 ? `0${num}` : num);

      const now = new Date();
      const formattedTimestamp = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()} ${padZero(now.getHours())}:${padZero(now.getMinutes())}`;
  
      const logEntry = {
        user,
        domainNames,
        timestamp: formattedTimestamp,
      };
  
      await setDoc(doc(historyCollectionRef, formattedTimestamp), logEntry);
      console.log('User action logged successfully.');
    } catch (error) {
      console.error('Error logging user action:', error);
    }
  };

  export const getHistory = async () => {
    try {
      const querySnapshot = await getDocs(historyCollectionRef);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching history data:', error);
      throw error; // You may want to handle this error in your component
    }
  };