import { useEffect, useState } from 'react';
import { markAllAsCheckedFalse } from '../services/mailServices';

export const MarkAllAsCheckedFalse = () => {
    const [status, setStatus] = useState(''); // State to track the status
  
    useEffect(() => {
      const markAllAsChecked = async () => {
        setStatus('Documents are being changed...');
  
        try {
          await markAllAsCheckedFalse();
          setStatus('Documents changed successfully.');
        } catch (error) {
          console.error(error);
          setStatus('Error changing documents.');
        }
      };
  
      markAllAsChecked();
    }, []);
  
    return (
      <div>
        <h2>{status}</h2>
      </div>
    );
  };

