import React, { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Button from '@mui/material/Button';
import { getHistory } from '../services/historyServices'; // Adjust the import path based on your file structure
import { toast } from 'react-toastify';

export const History = () => {
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    const fetchTimelineData = async () => {
      try {
        const historyData = await getHistory();
        setTimelineData(historyData);
      } catch (error) {
        // Handle error, e.g., show an error message to the user
      }
    };

    fetchTimelineData();
  }, []);

  const handleCopyToClipboard = (domainNames) => {
    try {
      navigator.clipboard.writeText(domainNames.join('\n'));
      toast.success(`Copied ${domainNames.length} domain names`)
      console.log('Copied to clipboard successfully!');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" mb={4}>
          History
        </Typography>
        <Timeline>
          {timelineData.map((item) => (
            <TimelineItem key={item.id}>
              <TimelineOppositeContent color="textSecondary">
                {item.timestamp}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleCopyToClipboard(item.domainNames)}
                  ref={(el) => (item.buttonRef = el)} // Assign a ref to the button
                  title={`${item.domainNames}`}
                >
                  {item.user}
                </Button>
                
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Container>
  );
};
