import { db } from '../firebase';
import { getDocs, updateDoc, doc, collection, query, where } from 'firebase/firestore';

const emailsCollectionRef = collection(db, "emails",);


export const getAll = async () => {
    try {
        const data = await getDocs(emailsCollectionRef);
        const filteredData = data.docs
            .filter((doc) => !doc.data().hasBeenChecked) // Only fetch docs with hasBeenChecked false
            .map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));

        return filteredData;
    } catch (error) {
        console.error(error);
    }
};




export const markAllAsChecked = async () => {
    try {
        const emailsSnapshot = await getDocs(emailsCollectionRef);
        const emailDocs = emailsSnapshot.docs;

        for (const email of emailDocs) {
            const emailDocRef = doc(emailsCollectionRef, email.id);
            await updateDoc(emailDocRef, { hasBeenChecked: true });
        }

        console.log('All documents marked as checked.');

    } catch (error) {
        console.error(error);
    }
};

export const markAllAsCheckedFalse = async () => {
    try {
        const emailsSnapshot = await getDocs(emailsCollectionRef);
        const emailDocs = emailsSnapshot.docs;

        for (const email of emailDocs) {
            const emailDocRef = doc(emailsCollectionRef, email.id);
            await updateDoc(emailDocRef, { hasBeenChecked: false });
        }

        console.log('All documents marked as checked.');

    } catch (error) {
        console.error(error);
    }
};





export const getMatchingDomainNames = async () => {
    try {
        const q = query(
            emailsCollectionRef,
            where('areTheTwoDatesTheSame', '==', true),
            where('hasBeenChecked', '==', false),

        );
        const querySnapshot = await getDocs(q);

        const matchingDomains = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            matchingDomains.push(data.domainName)
            console.log(matchingDomains);
        });
        console.log();

        return matchingDomains;
    } catch (error) {
        console.error('Error getting matching domain names:', error);
        throw error;
    }
};

