
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, Outlet } from 'react-router-dom';


export const Main = () => {
    const theme = createTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="Home" component={Link} to="/" />
          <Tab label="History" component={Link} to="/history" />
        </Tabs>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};
