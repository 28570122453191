// App.js

import './App.css';
import { Routes, Route } from 'react-router-dom';

import { Register } from './components/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Main } from './components/Main';
import { Logout } from './components/Logout';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { History } from './components/History';
import { MarkAllAsCheckedFalse } from './components/MarkAllAsChackedFalse'


const toastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="history" element={<History />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/markallascheckedfalse" element={<MarkAllAsCheckedFalse />} />

      </Routes>
      <ToastContainer {...toastOptions}/>
    </>
  );
}

export default App;
