import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {  useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { logout } from "../services/authServices";

export const Logout = () => {

    const [user] = useAuthState(auth)
    const navigate = useNavigate();
    



    useEffect(() => {
        if (user) {
            logout()
            .then(navigate('/'))
        }
    })
    return null;
};