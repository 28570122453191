import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions"; 


const firebaseConfig = {
  apiKey: "AIzaSyBqG1CuCr-YqyOpMDmscR9HciGr8ffHvYY",
  authDomain: "emailcollector-7fbf4.firebaseapp.com",
  projectId: "emailcollector-7fbf4",
  storageBucket: "emailcollector-7fbf4.appspot.com",
  messagingSenderId: "928595685685",
  appId: "1:928595685685:web:be1e3db3d432050f6a9c7c",
  measurementId: "G-C2GJ807F3C"
};
const app = initializeApp(firebaseConfig);

export const googleProvider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app); // Corrected usage
