import { db, auth  } from "../firebase"
import {
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  updateProfile
} from "firebase/auth";
import { getDoc, doc, setDoc, collection } from "firebase/firestore";

const usersCollectionRef = collection(db, "users");



export const register = async (email, password, username) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      const userDocRef = doc(usersCollectionRef, user.uid);
  
      const desiredData = {
        username: username,
        signedWithGoogle: false,
      };
      await updateProfile(user, {
        displayName: username
      });
      await setDoc(userDocRef, desiredData);
  
      return { _id: user.uid, ...desiredData };
  
    } catch (error) {
      throw new Error(error.message);
    }
  };
  
  export const login = async (email, password) => {
    try {
      const loginInfo = await signInWithEmailAndPassword(auth, email, password)
      const userDocRef = doc(usersCollectionRef, loginInfo.user.uid);
      const userSnap = await getDoc(userDocRef);
      const userSnapData = userSnap.data();
      return {
        ...userSnapData,
        _id: loginInfo.user.uid,
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  export const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw new Error(error.message);
    }
  };