import { useState } from "react";
import { login} from "../services/authServices";
import { useNavigate } from "react-router-dom";

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {  toast } from 'react-toastify';


export const Login = (props) => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    
    
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password){
            toast.error("Enter email and password");
            return;
        }
        try {
           const user = await login(email, password)
           
            navigate('/');
            toast.success(`Welcome, ${user.username}`)
        } catch (error) {
            console.log(error);
            toast.error("Invalid usename or password")
        }
    };

    

    const defaultTheme = createTheme();


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
               
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 0 }}
                        >
                            Sign In
                        </Button>
                       
                       
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};